{
  "sans-serif-font-family" : "Graphik Web",
  "normal-weight" : "400",
  "medium-weight" : "500",
  "bold-weight" : "700",

  "base-font-size" : "16px",

  "base-border-radius" : "7px",

  "shadow-hex": "rgba(170, 183, 178, 0.6)",
  "shadow": "var(--shadow, $shadow-hex)",
  "default-shadow-bottom-height": "2px",
  "default-box-shadow" : "0 1px $default-shadow-bottom-height 0 $shadow",

  "white": "#ffffff",

  "focus-hex": "#49a1ff",

  "primary-00-hex": "#009f6f",
  "primary-01-hex": "#008b61",
  "primary-02-hex": "#00704f",
  "primary-03-hex": "rgba(0, 159, 111, 0.35)",

  "secondary-00-hex": "#ffffff",
  "secondary-01-hex": "#ecf5f1",
  "secondary-02-hex": "#d5f0e5",
  "secondary-03-hex": "rgba(255, 255, 255, 0.35)",

  "light-00-hex": "#f6f8f6",
  "light-01-hex": "#d9e1e0",
  "light-02-hex": "#aab7b2",

  "dark-00-hex": "#2b3836",
  "dark-01-hex": "#505958",
  "dark-02-hex": "#7c8c88",
  "dark-03-hex": "rgba(43, 56, 54, 0.35)",

  "danger-00-hex": "#eb5534",
  "danger-01-hex": "#db5030",
  "danger-02-hex": "#ad4626",
  "danger-03-hex": "rgba(235, 85, 52, 0.35)",

  "warning-hex": "#fdb100",
  "success-hex": "#39cf94",
  "information-hex": "#6f6bf8",

  "brand-00-hex": "#a2ffbb",
  "brand-01-hex": "#00ff44",

  "focus": "var(--focus, $focus-hex)",

  "primary": {
    "00": "var(--primary-00, $primary-00-hex)",
    "01": "var(--primary-01, $primary-01-hex)",
    "02": "var(--primary-02, $primary-02-hex)",
    "03": "var(--primary-03, $primary-03-hex)"
  },

  "secondary": {
    "00": "var(--secondary-00, $secondary-00-hex)",
    "01": "var(--secondary-01, $secondary-01-hex)",
    "02": "var(--secondary-02, $secondary-02-hex)",
    "03": "var(--secondary-03, $secondary-03-hex)"
  },

  "light": {
    "00": "var(--light-00, $light-00-hex)",
    "01": "var(--light-01, $light-01-hex)",
    "02": "var(--light-02, $light-02-hex)"
  },

  "dark": {
    "00": "var(--dark-00, $dark-00-hex)",
    "01": "var(--dark-01, $dark-01-hex)",
    "02": "var(--dark-02, $dark-02-hex)",
    "03": "var(--dark-03, $dark-03-hex)"
  },

  "danger": {
    "00": "var(--danger-00, $danger-00-hex)",
    "01": "var(--danger-01, $danger-01-hex)",
    "02": "var(--danger-02, $danger-02-hex)",
    "03": "var(--danger-03, $danger-03-hex)"
  },

  "warning": "var(--warning, $warning-hex)",
  "success": "var(--success, $success-hex)",
  "information": "var(--information, $information-hex)",

  "brand": {
    "00": "var(--brand-00, $brand-00-hex)",
    "01": "var(--brand-01, $brand-01-hex)"
  },

  "base-font-color": "$dark-00"
}