const React         = require('react');
const PropTypes     = require('prop-types');
const ButtonPrimary = require('@cbrebuild/blocks-react').Buttons.ButtonPrimary;

const ERROR_MESSAGES = {
  404: 'We can’t find the page you’re looking for.',
  500: 'Something went wrong on our end.'
};

function ErrorPage(props) {
  if (!ERROR_MESSAGES[props.errorCode]) return null;

  return (
    <div className="is-flex is-fullheight is-grey-lighter has-padding-4-mobile">

      <div className="columns is-centered level">
        <div className="column is-4">
          <div className="error-page-element">
            <h5>{props.errorCode} Error</h5>
          </div>
          <div className="error-page-element">
            <h4>Oops! {props.errorMessage ? props.errorMessage : ERROR_MESSAGES[props.errorCode]}</h4>
          </div>
          <div className="error-page-element">
            <ButtonPrimary
              text="Return to Spacer"
              href="/pro/projects"
            />
          </div>
        </div>

        <div className="column is-5">
          <figure className="image">
            <img className="has-padding-top-1" src="/svg/illustrations/page-error-spilled-coffee-spacer.by-cbre.svg" alt="Illustration of a spilled coffee cup for Spacer by CBRE" />
          </figure>
        </div>
      </div>

    </div>
  );
}

ErrorPage.propTypes = {
  errorCode: PropTypes.number.isRequired,
  errorMessage: PropTypes.string
};

ErrorPage.defaultProps = {
  errorMessage: null
};

module.exports = ErrorPage;
